import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  /*updateToken,*/ logoutUser,
  getAdminUserDetail,
} from "./redux/ducks/auth/actions";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/login"));
const ForgotPassword = React.lazy(() => import("./views/auth/reset-password"));

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));
  // const refreshToken = localStorage.getItem('refreshToken') && JSON.parse(localStorage.getItem('refreshToken'));
  const expirationTime = token && token.expiresIn - new Date();
  // const refreshExpirationTime = refreshToken && refreshToken.expiresIn - new Date();
  //const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.userDetail);

  // useEffect(() => {
  // 	if ((expirationTime < 0) && (refreshExpirationTime > 0)) {
  // 		dispatch(updateToken());
  // 	};
  // }, [expirationTime, dispatch, refreshExpirationTime]);

  // useEffect(() => {
  // 	if ((expirationTime < 0) && (refreshExpirationTime < 0)) {
  // 		dispatch(logoutUser());
  // 	};
  // }, [expirationTime, dispatch, refreshExpirationTime]);

  useEffect(() => {
    if (
      expirationTime < 0 ||
      !token ||
      !user ||
      Object.values(user).length === 0 ||
      user?.accountStatus === "INACTIVE"
    ) {
      dispatch(logoutUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirationTime, dispatch]);

  useEffect(() => {
    user?.email && token && dispatch(getAdminUserDetail(user?.email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src =
      "https://rensource.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/bc54840da492f9ca037209037ef0522a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=749449d2";

    script.async = true;

    document.head.append(script);
  }, []);

  return (
    <React.Suspense fallback={loading}>
      <Switch>
        <Route
          exact
          path="/login"
          name="Login Page"
          render={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/resetPassword/:id"
          name="Reset Password"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/"
          name="Home"
          render={(props) => <TheLayout {...props} />}
        />
      </Switch>
    </React.Suspense>
  );
};

export default App;
