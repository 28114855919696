export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const ALL_VERIFICATION_REQUESTS = 'ALL_VERIFICATION_REQUESTS';
export const PERSONAL_VERIFICATION_REQUESTS = 'PERSONAL_VERIFICATION_REQUESTS';
export const LOCATION_VERIFICATION_REQUESTS = 'LOCATION_VERIFICATION_REQUESTS';

export const VERIFICATION_STATUS = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  BLACKLISTED: 'BLACKLISTED',
};
