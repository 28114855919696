import axios from 'axios';

axios.interceptors.request.use(config => {
    config.headers['fingerprint'] = `${require('browser-fingerprint')()}`;
    return config;
});

axios.interceptors.response.use(
    response => Promise.resolve(response),
    error => {
        if (error.response.status === 401
            || error.response.status === 403
        ) {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            window.location.assign("/login");
        } else {
            return Promise.reject(error);
        };
    }
);

export default axios;