import {
    LOADING_LECTURES,
    LOADING_LECTURES_SUCCESS,
    LOADING_LECTURES_ERROR
} from './constants';

const initialState = {
    isLoading: false,
    errorMsg: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_LECTURES: {
            return {
                ...state,
                isLoading: true
            };
        }

        case LOADING_LECTURES_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case LOADING_LECTURES_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload
            };
        }

        default: {
            return state
        }
    };
};