import {
  LOADING,
  ALL_VERIFICATION_REQUESTS,
  PERSONAL_VERIFICATION_REQUESTS,
  LOCATION_VERIFICATION_REQUESTS,
} from './constants';

const initialState = {
  isLoading: false,
  allVerificationRequests: {},
  personalVerificationRequest: {},
  locationVerificationRequest: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ALL_VERIFICATION_REQUESTS: {
      return {
        ...state,
        allVerificationRequests: action.payload,
      };
    }

    case PERSONAL_VERIFICATION_REQUESTS: {
      return {
        ...state,
        personalVerificationRequest: action.payload,
      };
    }

    case LOCATION_VERIFICATION_REQUESTS: {
      return {
        ...state,
        locationVerificationRequest: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
