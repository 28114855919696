import {
  CREATE_BANNER_POSITION,
  DELETE_BANNER_IMAGE,
  EMPTY_BANNER_IMAGES,
  ERROR,
  FETCHING,
  FETCH_BANNER_IMAGES,
  FETCH_BANNER_POSITIONS,
  FETCH_SINGLE_BANNER_POSITION,
  LOADING,
  UPDATE_BANNER_IMAGE,
  UPDATE_BANNER_POSITION,
  STATES
} from "./constants";

const initialState = {
  isLoading: false,
  fetching: false,
  bannerPositions: [],
  bannerPosition: {},
  bannerImages: [],
  states: [],
  errorMessage: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case CREATE_BANNER_POSITION: {
      return {
        ...state,
        bannerPositions: [...state.bannerPositions, action.payload],
      };
    }
    case FETCHING: {
      return {
        ...state,
        fetching: action.payload,
      };
    }
    case FETCH_BANNER_POSITIONS: {
      return {
        ...state,
        bannerPositions: action.payload,
        fetching: false,
      };
    }
    case FETCH_SINGLE_BANNER_POSITION: {
      return {
        ...state,
        bannerPosition: action.payload,
        fetching: false,
      };
    }
    case UPDATE_BANNER_POSITION: {
      return {
        ...state,
        bannerPositions: state.bannerPositions.map((position) => {
          if (position.id === action.bannerId) {
            position = action.payload;
          }
          return position;
        }),
      };
    }
    case FETCH_BANNER_IMAGES: {
      return {
        ...state,
        bannerImages: action.payload,
        fetching: false,
      };
    }
    case UPDATE_BANNER_IMAGE: {
      return {
        ...state,
        bannerImages: state.bannerImages.map((image) => {
          if (image.imageId === action.payload.imageId) {
            image = action.payload;
          }
          return image;
        }),
      };
    }
    case DELETE_BANNER_IMAGE: {
      return {
        ...state,
        bannerImages: state.bannerImages.filter(
          (image) => image.id !== action.payload
        ),
        fetching: false,
      };
    }
    case EMPTY_BANNER_IMAGES: {
      return {
        ...state,
        bannerImages: action.payload,
      };
    }
    case STATES: {
      return {
        ...state,
        states: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};