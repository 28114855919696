import {
  LOADING,
  FETCHING_INFLOW_TRANSACTIONS_SUCCESS,
  FETCHING_INTERNAL_TRANSACTIONS_SUCCESS,
  GET_WALLET_TO_BANK_TRANSACTIONS,
  GET_WALLET_TO_BANK_TRANSACTION_DETAILS,
  GET_WALLET_TO_BANK_TRANSACTIONS_LOGGED_FOR_REFUNDS,
  GET_BILL_PAYMENT_TRANSACTIONS,
  GET_BILL_PAYMENT_TRANSACTION_DETAILS,
  ERROR,
  LOG_TRANSACTION_FOR_REFUND,
  GET_TRANSACTION_STATUS_SUCCESS,
  FETCHING_TRANSACTION_SUCCESS,
  GET_TRANSACTION_DETAILS,
  GET_BILL_PAYMENT_SETTLEMENTS,
  GET_PAYMENT_LINKS,
  GET_PAYMENT_LINK_DETAILS,
  GET_PAYMENT_LINKS_SUMMARY,
} from './constants';

import { TRANSACTION_STATUS } from '../../../constants';

const initialState = {
  isLoading: false,
  internalTransactions: [],
  inflowTransactions: [],
  walletToBankTransactions: [],
  billPaymentsTransactions: [],
  walletToBankTransactionDetails: {
    transactionReference: '',
    description: '',
    senderPhoneNumber: '',
    accountNumber: '',
    bank: '',
    accountName: '',
    senderStartingBalance: 0,
    senderFinalBalance: 0,
    amount: 0,
    transactionStatus: '',
    confirmedDate: '',
    createdDate: '',
    manuallyProcessFlag: false,
    manuallyProcessedBy: '',
    transferLineItemDtos: [],
  },
  billPaymentsTransactionDetails: {
    createdAt: '',
    internalReference: '',
    msisdn: '',
    username: '',
    walletDebitRequest: {},
    walletDebitResponse: 
    "{\"transaction\":{\"amount\":0,\"internalTransactionReference\":\"\",\"senderPhoneNumber\":\"\",\"transactionStatus\":\"\",\"transactionType\":\"\",\"transactionLineItems\":[{\"amount\":\"0\",\"externalTransactionReference\":\"\",\"internalTransactionReference\":\"\",\"receiverAccountNumber\":null,\"receiverBank\":null,\"receiverName\":\"\",\"receiverWalletId\":\"\",\"senderName\":\"\",\"transactionStatus\":\"\"}]}}",
    billPurchaseStatus: '',
    providerResponseCode: '',
    billType: '',
    isRefunded: false,
  },
  transactions: [],
  transactionDetails: {
    transactionReference: '',
    description: '',
    senderFullName: '',
    senderPhoneNumber: '',
    receiverFullName: '',
    receiverPhoneNumber: '',
    accountNumber: '',
    bank: '',
    accountName: '',
    senderInitialBalance: 0,
    senderFinalBalance: 0,
    receiverInitialBalance: 0,
    receiverFinalBalance: 0,
    amount: 0,
    seerbitCharge: 0,
    transactionStatus: '',
    confirmedDate: '',
    createdDate: '',
    responseCode: '',
    responsePreview: '',
  },
  billPaymentSettlements: [],
  paymentLinks: [],
  totalPaymentLinks: 0,
  paymentLinkDetails: {},
  paymentLinkSummary: {
    'totalNoOfGeneratedLinks': 0,
    'closedNoOfGeneratedLinks': 0,
    'openedNoOfGeneratedLinks': 0
  },
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case FETCHING_INFLOW_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        inflowTransactions: action.payload,
        isLoading: false,
      };
    }
    case FETCHING_INTERNAL_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        internalTransactions: action.payload,
        isLoading: false,
      };
    }

    case FETCHING_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        transactions: action.payload,
      };
    }
    case GET_WALLET_TO_BANK_TRANSACTIONS: {
      return {
        ...state,
        walletToBankTransactions: action.payload,
      };
    }

    case GET_WALLET_TO_BANK_TRANSACTIONS_LOGGED_FOR_REFUNDS: {
      return {
        ...state,
        walletToBankTransactionsLoggedForRefunds: action.payload,
      };
    }

    case GET_WALLET_TO_BANK_TRANSACTION_DETAILS: {
      return {
        ...state,
        walletToBankTransactionDetails: action.payload,
      };
    }

    case GET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: action.payload,
      };
    }

    case LOG_TRANSACTION_FOR_REFUND: {
      return {
        ...state,
        walletToBankTransactionDetails: {
          ...state.walletToBankTransactionDetails,
          transactionStatus: TRANSACTION_STATUS.LOGGED_FOR_REFUND,
        },
      };
    }

    case GET_TRANSACTION_STATUS_SUCCESS: {
      return {
        ...state,
        walletToBankTransactionDetails: {
          ...state.walletToBankTransactionDetails,
          ...action.payload,
        },
      };
    }
    
    case GET_BILL_PAYMENT_SETTLEMENTS: {
      return {
        ...state,
        billPaymentSettlements: action.payload
      };
    }

    case GET_BILL_PAYMENT_TRANSACTIONS: {
      return {
        ...state,
        isLoading: false,
        billPaymentsTransactions: action.payload,
      };
    }

    case GET_BILL_PAYMENT_TRANSACTION_DETAILS: {
      return {
        ...state,
        billPaymentsTransactionDetails: action.payload,
      };
    }
    case GET_PAYMENT_LINKS: {
      return {
        ...state,
        paymentLinks: action.payload.content,
        totalPaymentLinks: action.payload.totalElements
      };
    }
    case GET_PAYMENT_LINK_DETAILS: {
      return {
        ...state,
        paymentLinkDetails: action.payload,
      };
    }
    case GET_PAYMENT_LINKS_SUMMARY: {
      return {
        ...state,
        paymentLinksSummary: action.payload,
      };
    }

    case ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
