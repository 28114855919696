import axios from './axios';

const currentAPI = process.env.REACT_APP_API_BASE_URL;
const trivialGameAPI = process.env.REACT_APP_TRIVIAL_GAME_API;

const bucketLink = 'https://spaces-images.s3.eu-central-1.amazonaws.com';

export {
    currentAPI,
    trivialGameAPI,
    axios,
    bucketLink
};