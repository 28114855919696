import {
    LOADING,
    FETCHING_APP_SETTINGS_SUCCESS,
} from './constants';

const initialState = {
    isLoading: false,
    appSettings: [],
    size: 1
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
         }

        case FETCHING_APP_SETTINGS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                appSettings: action.payload
            }
        }

        default: {
            return state
        }
    };
};