export const getId = url => {
    const lastSlash = url.lastIndexOf("/") + 1;
    return url.slice(lastSlash);
};
export const fromCamelCase = str => {
    let result = str.replace( /([A-Z])/g, " $1" );
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const fromStringToArray = str => {
    if (!str) return []
    return str.split(',')
}