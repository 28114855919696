import {
    LOADING,
    FETCH_REALM_SUCCESS,
    FETCH_USERS_IN_REALM_SUCCESS,
    FETCH_REALMS_FOR_USER_SUCCESS
} from "./constants";

const initialState = {
    isLoading: false,
    realms: [],
    size: 1,
    usersInRealm:[],
    usersInRealmSize: 1,
    realmsForUser: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }
       
        case FETCH_REALM_SUCCESS: {
            return {
                ...state,
                realms: action.payload,
                size: action.size,
                isLoading: false,
            };
        }
        case FETCH_USERS_IN_REALM_SUCCESS: {
            return {
                ...state,
                usersInRealm: action.payload,
                usersInRealmSize: action.size,
                isLoading: false,
            };
        }
        case FETCH_REALMS_FOR_USER_SUCCESS: {
            return {
                ...state,
                realmsForUser: action.payload,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};