import {
  LOADING,
  ERROR,
  BILLS_PAYMENT_SERVICE_PROVIDERS,
  NEW_BILLS_PAYMENT_SERVICE_PROVIDER,
  BILLS_PAYMENT_SERVICE_PROVIDER_DETAILS,
  BILLER_DETAILS_UPDATED,
  BILLS_PAYMENT_CATEGORIES,
  ADD_BILLS_PAYMENT_CATEGORIES,
  UPDATE_BILLS_PAYMENT_CATEGORIES,
} from './constants';

const initialState = {
  isLoading: false,
  serviceProviders: {
    content: [],
    totalPage: 1,
    totalElements: 0,
  },
  serviceProvider: {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    walletId: '',
    description: '',
    createdBy: '',
    billCategories: [],
  },
  billCategories: {
    content: [],
    totalPage: 1,
    totalElements: 0,
  },
  billsPaymentCategories: {
    content: [],
    totalPages: 1,
    totalElements: 0,
  },
  error: '',
};

const addNewServiceProvider = (newServiceProvider, existingProviders) => {
  const allProviders = [...existingProviders];
  allProviders.unshift(newServiceProvider);
  return allProviders;
};

const updateBiller = (update, existingProvider) => {
  const billerId = update?.id;
  const billCategoryId = update?.billCategoryId;

  const provider = { ...existingProvider };

  const billCategoryIndex = provider?.billCategories.findIndex(
    (billCategory) => billCategory?.id === billCategoryId
  );

  const billCategory = provider?.billCategories[billCategoryIndex];

  const billerIndex = billCategory?.billers.findIndex(
    (biller) => biller?.biller?.id === billerId
  );

  const originalBillerDetails = billCategory?.billers[billerIndex];

  provider.billCategories[billCategoryIndex].billers.splice(billerIndex, 1, {
    ...originalBillerDetails,
    turnedOn: update?.turnedOn,
    settlementPercentage: update?.settlementPercentage,
    biller: {
      ...originalBillerDetails?.biller,
      ...update,
    },
  });

  return provider;
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOADING: {
			return {
				...state,
				isLoading: action.payload,
			};
		}

		case BILLS_PAYMENT_SERVICE_PROVIDERS: {
			return {
				...state,
				serviceProviders: action.payload,
			};
		}

		case NEW_BILLS_PAYMENT_SERVICE_PROVIDER: {
			return {
				...state,
				serviceProviders: {
					...state.serviceProviders,
					content: addNewServiceProvider(
						action.payload,
						state.serviceProviders.content
					),
				},
			};
		}

		case BILLS_PAYMENT_SERVICE_PROVIDER_DETAILS: {
			return {
				...state,
				serviceProvider: action.payload,
			};
		}

		case BILLER_DETAILS_UPDATED: {
			return {
				...state,
				serviceProvider: updateBiller(action.payload, state.serviceProvider),
			};
		}

		case BILLS_PAYMENT_CATEGORIES: {
			return {
				...state,
				billsPaymentCategories: action.payload,
			};
		}

		case ADD_BILLS_PAYMENT_CATEGORIES: {
			const categories = action.payload;
			return {
				...state,
				billsPaymentCategories: [categories, ...state.billsPaymentCategories?.content],
			};
		}

		case UPDATE_BILLS_PAYMENT_CATEGORIES: {
			return {
				...state,
				billsPaymentCategories: state.billsPaymentCategories?.content.map((category) => {
					if (category.id === action.billId) {
						category = action.payload;
					}
					return category;
				}),
			};
		}

		case ERROR: {
			return {
				...state,
				error: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};
