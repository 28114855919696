import {
	LOADING,
	ERROR,
	GET_BULK_PAYOUTS,
	GET_BULK_PAYOUT_DETAILS,
	ADD_BULK_PAYOUT,
} from './constants';

const initialState = {
	isLoading: false,
	bulkPayoutTransactions: {
		content: [],
		totalPage: 1,
		totalElements: 0,
	},
	bulkPayoutDetails: {
		id: '',
		amount: 0,
		createdAt: '',
		beneficiaryPhoneNumber: '',
		transactionId: '',
	},
	error: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOADING: {
			return {
				...state,
				isLoading: action.payload,
			};
		}

		case GET_BULK_PAYOUTS: {
			return {
				...state,
				bulkPayoutTransactions: action.payload,
			};
		}

		case GET_BULK_PAYOUT_DETAILS: {
			return {
				...state,
				bulkPayoutDetails: action.payload,
			};
		}

		case ADD_BULK_PAYOUT: {
			const payouts = action.payload;
			return {
				...state,
				bulkPayoutTransactions: [
					payouts,
					...state.bulkPayoutTransactions?.content,
				],
			};
		}

		case ERROR: {
			return {
				...state,
				error: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};
