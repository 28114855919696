import {
    LOADING,
    FETCHING,
    SAVE_ADMINS,
    UPDATE_ADMIN,
    ADMIN_ROLES,
    ADD_SINGLE_ADMIN
} from "./constants";

const initialState = {
    isLoading: false,
    fetching: false,
    admins: [],
    roles: [],
    size: 1
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }
        case FETCHING: {
            return {
                ...state,
                fetching: action.payload
            };
        }
        case SAVE_ADMINS: {
            return {
                ...state,
                admins: action.payload,
                size: action.size,
                fetching: false,
            };
        }
        case ADMIN_ROLES: {
            return {
                ...state,
                roles: action.payload,
            };
        }
        case UPDATE_ADMIN: {
            const email = action.payload.email || "";
            return {
                ...state,
                admins: state.admins.map((admin) => {
                    if (admin.email === email) {
                        admin = action.payload;
                    };
                    return admin;
                }),
            };
        }
        case ADD_SINGLE_ADMIN: {
            const admin = action.payload;
            return {
                ...state,
                admins: [admin, ...state.admins] 
            };
        }
        default: {
            return state;
        }
    }
};