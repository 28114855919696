import {
    LOADING,
    SAVE_METRICS
} from "./constants";

const initialState = {
    isLoading: false,
    metrics: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }
        case SAVE_METRICS: {
            return {
                ...state,
                metrics: action.payload
            };
        }
        default: {
            return state;
        }
    }
};