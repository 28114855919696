import { theme } from "@chakra-ui/core";

export default {
    ...theme,
    fonts: {
        ...theme.fonts,
        body: `'Montserrat', sans-serif`,
        heading: `'Montserrat', sans-serif`
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
    },
    fontWeights: {
        normal: 100,
        medium: 500,
        bold: 700,
    }
};
