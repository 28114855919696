import { push, replace } from 'connected-react-router';
import { SET_USER_AUTH_STATUS, USER_LOGOUT, SET_MENU, LOADING, SET_USER_DETAIL } from '../constants';
import { currentAPI, axios } from '../../../../config';

export const loginToSpaces = (
    LoginDTO,
    displayError
) => async dispatch => {
    dispatch({type: LOADING, payload: true });
    try {
        const loginResponse = await axios.post(
            `${currentAPI}/token/generate-token`,
            JSON.stringify(LoginDTO),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        if (loginResponse.status === 200) {
            const { expiresIn, token, refreshToken, refreshExpiresIn, userId } = loginResponse.data || {};
            localStorage.setItem("userId", userId);
            localStorage.setItem('token', JSON.stringify({ token, expiresIn }));
            localStorage.setItem('refreshToken', JSON.stringify({ refreshToken, expiresIn: refreshExpiresIn }));
            dispatch({ type: SET_USER_AUTH_STATUS, payload: true });
            dispatch({type: LOADING, payload: false });
            dispatch(push('/'));
        };
    } catch (error) {
        dispatch({type: LOADING, payload: false });
        displayError({
            title: "An error occurred.",
            description: (error?.response?.status === 400) ? error?.response?.data?.message : error.message,
            status: "error",
            duration: 4000,
            isClosable: true
        });
    }
};

export const login = (
    LoginDTO,
    displayError
) => async dispatch => {
    dispatch({type: LOADING, payload: true });
    
    try {

        const loginResponse = await axios.post(
            `${currentAPI}/portal/users/login`,
            JSON.stringify(LoginDTO),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );


        if (loginResponse.status === 200) {
            const { token, expiresIn } = loginResponse.data;
            localStorage.setItem('token', JSON.stringify({ token, expiresIn }));
            const res = await dispatch(getAdminUserDetail(LoginDTO.email))
            res && dispatch({ type: SET_USER_AUTH_STATUS, payload: true });
            dispatch({type: LOADING, payload: false });
            res && dispatch(push('/'));
        };
    } catch (error) {
        dispatch({type: LOADING, payload: false });
        displayError({
            title: "An error occurred.",
            description: (error?.response?.status === 417) ? error?.response?.data?.message : error.message,
            status: "error",
            duration: 4000,
            isClosable: true
        });
    }
};

export const getAdminUserDetail = (email) => async dispatch => {
    try {
        const token = JSON.parse(localStorage.getItem('token')).token;
        const response = await axios.get(
            `${currentAPI}/portal/users/detail/${email}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (response.status === 200) {
            dispatch({ type: SET_USER_DETAIL, payload: response?.data?.data || {}});
            return response?.data?.data || {};
        };
    } catch (error) {
        console.log(error)
    }
};

export const requestForgotPasswordToken = (
    email,
    displayError
) => async dispatch => {
    dispatch({type: LOADING, payload: true });
    try {
        const response = await axios.post(
            `${currentAPI}/portal/users/passwordReset/generation/${email}`,
            null,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        if (response.status === 200) {
            dispatch({type: LOADING, payload: false });
            displayError({
                title: "Success",
                description: response.data.message,
                status: "success",
                duration: 4000,
                isClosable: true
            });
        } else {
            dispatch({type: LOADING, payload: false });
        }        
    } catch (error) {
        dispatch({type: LOADING, payload: false });
        displayError({
            title: "An error occurred.",
            description: (error?.response?.status === 404) ? error?.response?.data?.message : error.message,
            status: "error",
            duration: 4000,
            isClosable: true
        });
    }
};

export const resetPassword = (
    param,
    token,
    displayError
) => async dispatch => {
    dispatch({type: LOADING, payload: true });
    try {
        const response = await axios.post(
            `${currentAPI}/portal/users/resetPassword/${token}`,
            JSON.stringify(param),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        );

        if (response.status === 200) {
            dispatch({type: LOADING, payload: false });
            dispatch(replace('/login'));
        };
    } catch (error) {
        dispatch({type: LOADING, payload: false });
        displayError({
            title: "An error occurred.",
            description: (error?.response?.status === 417) ? error?.response?.data?.message : error.message,
            status: "error",
            duration: 4000,
            isClosable: true
        });
    }
};

export const logoutUser = () => dispatch => {
    dispatch({ type: USER_LOGOUT });
};

export const updateToken = () => async () => {
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken')).refreshToken || null;

    const responseUpdateToken = await axios.post(
        `${currentAPI}/api/token/refresh-token`,
        JSON.stringify({ refreshToken })
    );

    if (responseUpdateToken.status === 200) {
        const tokenData = responseUpdateToken.data;

        localStorage.setItem(
            'token',
            JSON.stringify({
                token: tokenData.token,
                expiresIn: tokenData.expiresIn
            })
        );

        localStorage.setItem(
            'refreshToken',
            JSON.stringify({
                refreshToken: tokenData.refreshToken,
                expiresIn: tokenData.refreshExpiresIn
            })
        );
    };
};

export const sideBarToggle = (data) => dispatch => {
    dispatch({ type: SET_MENU, payload: data });
};