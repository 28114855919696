import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { ConnectedRouter } from "connected-react-router";
import configureStore from './redux/store';
import history from './utils/history';
import App from './App';
import theme from './theme';
import 'bootstrap/dist/css/bootstrap.min.css';

const {store, persistor}= configureStore();

render(
    <BrowserRouter>
        <Provider store={store}>
            <ConnectedRouter history={history}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <CSSReset />
                    <App />
                </ThemeProvider>
             </PersistGate>
            </ConnectedRouter>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);