export const LOADING = 'banner_position/loading';
export const ERROR = 'banner_position/error';
export const CREATE_BANNER_POSITION = 'banner_position/create';
export const FETCHING = 'banner_position/fetching';
export const FETCH_BANNER_POSITIONS = 'banner_position/fetch';
export const UPDATE_BANNER_POSITION = 'banner_position/update';
export const FETCH_SINGLE_BANNER_POSITION = 'banner_position/fetch_single';
export const FETCH_BANNER_IMAGES = 'banner_position/banner_images/fetch';
export const EMPTY_BANNER_IMAGES = 'banner_position/banner_images/clear';
export const UPDATE_BANNER_IMAGE = 'banner_position/banner_image/update';
export const DELETE_BANNER_IMAGE = 'banner_position/banner_image/delete';
export const CREATE_BANNER_IMAGE = 'banner_position/banner_image/create';
export const STATES = 'banner_position/states';