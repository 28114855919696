import {
    LOADING_AGENCY_APPLICATIONS,
    AGENCY_APPLICATIONS_ERROR,
    AGENCY_APPLICATIONS_SUCCESS,
    CHOOSE_AGENCY_APPLICATION
} from "./constants";

const initialState = {
    isLoading: false,
    errorMsg: "",
    applications: [],
    chosenApplication: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_AGENCY_APPLICATIONS: {
            return {
                ...state,
                isLoading: true
            }
        }

        case AGENCY_APPLICATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                errorMsg: "",
                applications: action.payload
            }
        }

        case AGENCY_APPLICATIONS_ERROR: {
            return {
                ...state,
                isLoading: false,
                errorMsg: action.payload
            }
        }
        case CHOOSE_AGENCY_APPLICATION : {
            return {
                ...state,
                isLoading: false,
                errorMsg: "",
                chosenApplication: action.payload
            }
        }
        default: {
            return state
        }
    }
    ;
};