export const LOADING = 'transaction/loading';
export const FETCHING_INFLOW_TRANSACTIONS_SUCCESS = 'inflow_transactions_success';
export const FETCHING_INTERNAL_TRANSACTIONS_SUCCESS = 'internal_transactions_success';
export const ERROR = 'ERROR';
export const GET_WALLET_TO_BANK_TRANSACTIONS = 'GET_WALLET_TO_BANK_TRANSACTIONS';
export const FETCHING_TRANSACTION_SUCCESS = 'transactions/fetching_transactions_success';
export const GET_ALL_BANKS = "get_all_banks";
export const GET_TRANSACTION_STATUS_SUCCESS = 'get_transaction_status_success';
export const GET_WALLET_TO_BANK_TRANSACTIONS_LOGGED_FOR_REFUNDS = 'get_wallet_to_bank_transactions_logged_for_refunds';
export const GET_WALLET_TO_BANK_TRANSACTION_DETAILS = 'get_wallet_to_bank_transaction_details';
export const LOG_TRANSACTION_FOR_REFUND = 'log_transaction_for_refund';
export const GET_TRANSACTION_DETAILS = 'get_transaction_details';
export const GET_BILL_PAYMENT_TRANSACTIONS = 'get_bill_payment_transactions';
export const GET_BILL_PAYMENT_TRANSACTION_DETAILS = 'get_bill_payment_transaction_details';
export const GET_BILL_TRANSACTION_STATUS_SUCCESS = 'get_bill_transaction_status_success';
export const GET_BILL_PAYMENT_SETTLEMENTS = 'GET_BILL_PAYMENT_SETTLEMENTS';
export const GET_PAYMENT_LINKS = 'GET_PAYMENT_LINKS';
export const GET_PAYMENT_LINK_DETAILS = 'GET_PAYMENT_LINK_DETAILS';
export const GET_PAYMENT_LINKS_SUMMARY = 'GET_PAYMENT_LINKS_SUMMARY';


