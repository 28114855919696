import {
    LOADING,
    FETCHING_CUSTOMERS_SUCCESS,
    ADD_SINGLE_CUSTOMER
} from './constants';

const initialState = {
    isLoading: false,
    customers: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
        }

        case FETCHING_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customers: action.payload
            }
        }

        case ADD_SINGLE_CUSTOMER: {
            const customer = action.payload;
            return {
                ...state,
                customers: [customer, ...state.customers] 
            };
        }

        default: {
            return state
        }
    };
};