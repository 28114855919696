import { LOADING, GET_FEATURES } from './constants'

const initialState = {
    isLoading: false,
    features: []
};

export default (state = initialState, action) => {
    switch(action.type){
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
        }

        case GET_FEATURES: {
            return {
                ...state,
                features: action.payload
            }
        }

        default: {
            return state
        }
    }
}
