import {
    LOADING,
    FETCHING_UNBAR_SUCCESS
} from './constants';

const initialState = {
    isLoading: false,
    unbarred: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
         }

        case FETCHING_UNBAR_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                unbarred: action.payload
            }
        }

        default: {
            return state
        }
    };
};