import {
    LOADING,
    FETCHING_USERS_SUCCESS,
    UPDATE_USER,
    FETCHING_OTP
} from "./constants";

const initialState = {
    isLoading: false,
    fetchingOtp: false,
    users: [],
    size: 1
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }
        case FETCHING_OTP: {
            return {
                ...state,
                fetchingOtp: action.payload
            };
        }
        case FETCHING_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload,
                size: action.size,
                isLoading: false,
            };
        }
        case UPDATE_USER: {
            const userId = action.payload.userId;
            const barStatus  = action.payload.barStatus;
            return {
                ...state,
                users: state.users.map((user) => {
                    if (user.userId === userId) {
                        user.barStatus = barStatus
                    }; 
                    return user;
                }),
            };
        }
        default: {
            return state;
        }
    }
};