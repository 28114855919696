import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import {
  auth,
  agents,
  educational,
  commissions,
  agencyApplications,
  users,
  realms,
  admins,
  dashboard,
  unbarred,
  shs,
  bannerManagement,
  transactions,
  reconciliation,
  appSettings,
  billsPayment,
  kyc,
  internationalization,
  bulkPayout,
  features
} from "./ducks";

export default (history) =>
  combineReducers({
    auth,
    agents,
    educational,
    commissions,
    agencyApplications,
    users,
    realms,
    admins,
    dashboard,
    unbarred,
    shs,
    bannerManagement,
    transactions,
    reconciliation,
    appSettings,
    billsPayment,
    kyc,
    internationalization,
    bulkPayout,
    features,
    router: connectRouter(history),
  });
