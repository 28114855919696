import {
    LOAD_COMMISSIONS,
    LOAD_COMMISSIONS_SUCCESS,
    COMMISSIONS_ERROR,
    ADD_COMMISSION,
} from "./constants"

const initialState = {
    isLoading: false,
    errorMessage: null,
    commissions: null
};

const setCommission = (data, commission) => {
    let newArr;
    if (data.some(arrCommission => arrCommission.commissionId === commission.commissionId)) {
        newArr = data.map(arrCommission => arrCommission.commissionId === commission.commissionId ? commission : arrCommission)
    } else {
        newArr = [...data, commission]
    }
    return newArr
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_COMMISSIONS: {
            return {
                ...state,
                isLoading: true
            };
        }

        case LOAD_COMMISSIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                commissions: action.payload,
                error: null
            };
        }
        case COMMISSIONS_ERROR : {
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            }
        }
        case ADD_COMMISSION: {
            return {
                ...state,
                isLoading: false,
                errorMessage: null,
                commissions: {
                    ...state.commissions,
                    data: setCommission(state.commissions.data, action.payload)
                }
            }
        }
        default: {
            return state
        }
    };
};