import {
    SET_USER_AUTH_STATUS,
    USER_LOGOUT,
    SET_MENU,
    LOADING,
    SET_USER_DETAIL
} from './constants';
import { fromStringToArray } from '../../../utils/helpers';

const initialState = {
    isLoading: false,
    isAuthenticated: !!JSON.parse(localStorage.getItem("token")),
    userId: localStorage.getItem("userId"),
    userDetail: {},
    permissions: [],
    sidebarShow: 'responsive'
};  

export default (state = initialState, action) => {
    switch(action.type) {
        case LOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
        }

        case SET_USER_AUTH_STATUS: {
            return {
                ...state,
                isAuthenticated: action.payload
            };
        }

        case SET_USER_DETAIL: {
            return {
                ...state,
                userDetail: action.payload,
                permissions: fromStringToArray(
                    action.payload?.adminAccountType?.permissions || ""
                )
            };
        }

        case SET_MENU : {
            return {
                ...state,
                sidebarShow: action.payload
            };
        }

        case USER_LOGOUT: {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");

            return {
                ...state,
                isAuthenticated: false,
                userDetail: {},
                permissions: []
            };
        }

        default: {
            return state
        }
    };
};