export const ADMIN_TYPE = {
  SA: 'Super Admin',
  AO: 'Agent Ops',
  CS: 'Customer Support',
  F: 'Finance',
};

export const ROLES = {
  ROLE_USER: 'ROLE_USER',
  ROLE_AGENT: 'ROLE_AGENT'
  //     ROLE_ADMIN: 'ROLE_ADMIN',
  //     ROLE_APP_MANAGER: 'ROLE_APP_MANAGER',
  //     ROLE_APP_DEVELOPER: 'ROLE_APP_DEVELOPER',
  //     ROLE_APP_AGENT: 'ROLE_APP_AGENT',
  //     ROLE_APP_SUPPORT: 'ROLE_APP_SUPPORT',
};

export const AGENT_STATES = {
  INCOMPLETE: 'INCOMPLETE',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  BLACKLISTED: 'BLACKLISTED',
};

export const PROPERTY_STATUS = {
  CART: 'CART',
  FRAUD_DETECTION: 'FRAUD_DETECTION',
  GROUPBUY: 'GROUPBUY',
  SUPPORT_CONTACT: 'SUPPORT_CONTACT',
  WARENEXT_SHIPPING_OPTIONS: 'WARENEXT_SHIPPING_OPTIONS',
  DELIVERY_ADDRESS_EDIT_LIMIT: 'DELIVERY_ADDRESS_EDIT_LIMIT'
};

export const DOCUMENT_STATES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const APPLICATION_STATES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DOWNGRADED: 'DOWNGRADED',
};

export const PERMISSIONS = {
  CREATE_ADMIN_USERS: 'CREATE_ADMIN_USERS',
  DEACTIVATE_ADMIN_USERS: 'DEACTIVATE_ADMIN_USERS',
};

export const BAR_SEARCH_TERM = {
  all: 'ALL',
  name: 'FIRST_LAST',
  phoneNumber: 'MSISDN',
  startDate: 'DATE_RANGE',
  endDate: 'DATE_RANGE',
  adminEmailAddress: 'ADMIN_EMAIL',
};

export const BAR_SORT_CRITERIA = {
  ASC: 'ASC',
  DESC: 'DESC',
  ADMIN_EMAIL_ASC: 'ADMIN_EMAIL_ASC',
  ADMIN_EMAIL_DESC: 'ADMIN_EMAIL_DESC',
};

export const STATES = [
  'Abia',
  'Adamawa',
  'Anambra',
  'Akwa Ibom',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Enugu',
  'Edo',
  'Ekiti',
  'FCT - Abuja',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Taraba',
  'Yobe',
  'Zamfara',
];

export const TRANSACTION_STATUS = {
  ALL_STATUS: 'ALL STATUS',
  PENDING: 'PENDING',
  INITIALIZE: 'INITIALIZE',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
  NOT_COMPLETED: 'NOT_COMPLETED',
  LOGGED_FOR_REFUND: 'LOGGED_FOR_REFUND',
  REFUNDED: 'REFUNDED',
};

export const TRANSACTION_TYPE = {
  CREDIT_BANK: 'CREDIT_BANK',
  TOP_UP_WALLET: 'TOP_UP_WALLET',
  WALLET_TO_WALLET: 'WALLET_TO_WALLET',
  PAY_WITH_WALLET: 'PAY_WITH_WALLET',
  COMMISSION: ' COMMISSION',
  SETTLEMENT: '	SETTLEMENT',
  AIRTIME_PURCHASE: '	AIRTIME_PURCHASE',
  DATA_PURCHASE: 'DATA_PURCHASE',
  UTILITIES: 'UTILITIES'
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const RECONCILIATION_TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED'
}

export const BILL_PAYMENT_TYPE = {
  CREDIT_BANK: 'CREDIT_BANK',
  TOP_UP_WALLET: 'TOP_UP_WALLET',
  WALLET_TO_WALLET: 'WALLET_TO_WALLET',
  PAY_WITH_WALLET: 'PAY_WITH_WALLET',
  COMMISSION: 'COMMISSION',
  SETTLEMENT: 'SETTLEMENT',
  AIRTIME_PURCHASE: 'AIRTIME_PURCHASE',
  DATA_PURCHASE: 'DATA_PURCHASE',
  UTILITIES: 'UTILITIES',
}

export const VERIFICATION_STATUS = {
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  BLACKLISTED: 'BLACKLISTED',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
}

export const PAYMENT_LINK_STATUS = {
  OPENED: 'OPENED',
  CLOSED: 'CLOSED'
}
export const NETWORKS = {
  MERCHBUY: 'MerchBuy Agent Network',
}

